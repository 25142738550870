<template>
  <div class="introduction">
    <Banner/>
    <div class="firstPlate">
      <div class="solutionPublicTitle">
        <p>公司简介</p>
        <p>COMPANY PROFILE</p>
      </div>
      <div class="hy_gsjj_body">
        <div><img src="@/assets/imgs/about/com_img.jpg" alt=""></div>
        <div>
<!--          <p><span>慧言科技（天津）有限公司成立于2018年04月03日，依托于天津大学天津市认知计算与应用重点实验室，是一家致力于细分领域语音产品研发的人工智能公司。</span></p>-->
<!--          <p><span>公司的主要技术产品有语音识别，语音合成，智能翻译，语音扩展。旨在用一流的语音处理核心技术，提供适应各行各业的应用和解决方案，为用户带来“锦上添花”、“雪中送炭”的定制化体验，提高人们的生活质量，让生活更加美好。</span></p>-->
          <p><span>慧言科技（天津）有限公司是一家业内领先的言语交互高新技术企业。公司基于自主可控“海河·谛听”言语交互意图理解大模型，研发语音识别、语音生成、语义理解、文本生成、知识问答、机器翻译、声纹识别等全链路言语交互技术，支持中英日韩法西俄德维藏泰越等20余个语种，言语交互技术达到国内领先。同时，公司为合作伙伴提供大模型+教育、大模型+办公、大模型+金融等多行业综合解决方案。</span></p>
          <p><span></span></p>
        </div>
      </div>
    </div>
    <div class="secondPlate">
      <div>
        <div class="solutionPublicTitle">
          <p>发展历程</p>
          <p>DEVELOPMENT HISTORY</p>
        </div>
        <div ref="getheight" id="hy_fzlc_body" class="hy_fzlc_body">
          <div class="timeline">
            <div class="wrapper clearfix-time">
              <ul class="clearfix-time">
                <li>
                  <b></b>
                  <div><h4>2023</h4><i></i></div>
                  <span>05月</span>
                  <p>
                    <span>发布“海河·谛听”言语交互意图深度理解大模型V1.0</span>
                  </p>
                </li>
                <li>
                  <b></b>
                  <div><h4>2022</h4><i></i></div>
                  <span>11月</span>
                  <p>
                    <span>成功研发自主可控全链路言语交互技术，<br>支持语种数量超过20个</span>
                  </p>
                  <span>08月</span>
                  <p>
                    <span>基于天津市人工智能计算中心（算力）<br>研发海河·谛听”言语交互大模型</span>
                  </p>
                </li>
                <li>
                  <b></b>
                  <i><img src="@/assets/imgs/about/his_rocket.png" alt=""></i>
                  <div><h4>2021</h4><i></i></div>
                  <span>12月</span>
                  <p>
                    <span>通过国家高新技术企业认定</span>
                  </p>
                  <span>09月</span>
                  <p>
                    <span>语音识别2.0系统通过中国信通院权威评测，<br>性能达到国内领先水平</span>
                  </p>
                  <span>01月</span>
                  <p>
                    <span>成立天津大学-慧言科技人工智能实验室，<br>加速言语交互核心技术研发</span>
                  </p>
                </li>
                <li>
                  <b></b>
                  <div><h4>2020</h4><i></i></div>
                  <span>10月</span>
                  <p>
                    <span>入选2020年天津市市级重点支持的“项目+团队”名单</span>
                  </p>
                  <span>07月</span>
                  <p>
                    <span>完成Pre-A轮融资</span>
                  </p>
                </li>
                <li>
                  <b></b>
                  <i><img src="@/assets/imgs/about/his_rocket.png" alt=""></i>
                  <div><h4>2019</h4><i></i></div>
                  <span>05月</span>
                  <p>
                    <span>完成语音识别、语音合成、机器翻译<br>核心引擎1.0研发并上线慧言AI平台</span>
                  </p>
                </li>
                <li>
                  <b></b>
                  <i><img src="@/assets/imgs/about/his_rocket.png" alt=""></i>
                  <div><h4>2018</h4><i></i></div>
                  <span>04月</span>
                  <p>
                    <span>慧言科技（天津）有限公司成立，<br>同时获得天使轮融资</span>
                  </p>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="timelinePage">
          <div class="top_page" @click="top_page"></div>
          <div class="bottom_page" @click="bottom_page"></div>
        </div>
      </div>
    </div>
    <div class="thirdPlate">
      <div class="solutionPublicTitle">
        <p>创始人团队</p>
        <p>FOUNDER TEAM MEMBERS</p>
      </div>
      <div class="hy_csrtd_body">
        <div>
          <img src="@/assets/imgs/about/chuangshiren01.png" alt="">
          <p>王龙标</p>
          <span class="fubiaoti">创始人&CEO</span>
          <span>国家级青年人才，天津大学人工智能学院副院长，天津市认知计算与应用重点实验室主任，获得2022年天津市科技进步一等奖，拥有丰富的团队管理经验及人工智能领域科研成果转化经验。</span>
        </div>
        <div>
          <img src="@/assets/imgs/about/chuangshiren02.png" alt="">
          <p>党建武</p>
          <span class="fubiaoti">联合创始人&首席科学家</span>
          <span>国家级人才，国家973项目首席科学家，中国计算机学会“语音对话与听觉”专委会首届主任，天津大学特聘教授、原计算机科学与技术学院院长，曾任日本北陆先端科学技术大学智能实验室主任。</span>
        </div>
        <div>
          <img src="@/assets/imgs/about/chuangshiren03.png" alt="">
          <p>王宇光</p>
          <span class="fubiaoti">联合创始人&首席技术官</span>
          <span>超过十年语音、自然语言处理领域技术和产品研发经验，曾任多家上市公司机器翻译项目、机器同传项目负责人，曾获5次语音/机器翻译领域世界冠军。</span>
        </div>
      </div>
    </div>
    <div class="fourthPlate">
      <div class="solutionPublicTitle">
        <p>资质荣誉</p>
        <p>QUALIFICATIONS HONORS</p>
      </div>
      <div class="hy_zzry_body">
        <div class="honor_txt">
          <ul>
            <li><i></i><span>2020年第三批入库国家科技型中小企业。</span></li>
            <li><i></i><span>2020年第一批入库天津高新区科技型企业培育库。</span></li>
            <li><i></i><span>2020年第三批入库天津市雏鹰企业。</span></li>
            <li><i></i><span>连续2年承担天津市新一代人工智能科技重大专项项目。</span></li>
            <li><i></i><span>入选2020年天津市市级重点支持“项目+团队”。</span></li>
            <li><i></i><span>专利、顶会论文、软著100多篇。</span></li>
          </ul>
        </div>
        <div class="honor_img">
          <img src="@/assets/imgs/about/hon_01.jpg" alt="">
          <img src="@/assets/imgs/about/hon_02.jpg" alt="">
          <img src="@/assets/imgs/about/hon_03.jpg" alt="">
        </div>
      </div>
    </div>
    <div class="fivePlate">
      <div class="solutionPublicTitle">
        <p>联系我们</p>
        <p>CONTACT US</p>
      </div>
      <div class="hy_lxwm_body">
        <div class="contact_info">
          <div class="contact_info_top">
            <p class="company_name">
              <span>慧言科技（天津）有限公司</span>
              <span>Huiyan Technology (Tianjin) Co.,Ltd.</span>
            </p>
            <p><span>地址：天津市河北区鸿顺里街诺诚广场1号楼18层17号</span></p>
            <p><span>邮箱：bd@huiyan-tech.com</span></p>
            <p>
              <span>
                <span>电话：</span>
                <span>
                  022-23726181 (常规咨询、商务咨询)<br>
<!--                  刘经理&nbsp;&nbsp;156-9220-3015（商务咨询）<br>-->
<!--                  许经理&nbsp;&nbsp;189-1031-9816（商务咨询）-->
                </span>
              </span>
            </p>
          </div>
          <div class="contact_info_bottom">
            <p><span>微信订阅号</span></p>
            <img src="@/assets/imgs/bottom_wc.jpg" alt="">
          </div>
        </div>
        <div class="contact_map">
          <div id="container" class="containerMap"></div>
        </div>
      </div>
    </div>
    <div class="sixPlate">
      <div class="solutionPublicTitle">
        <p>合作伙伴</p>
        <p>COOPERATIVE PARTNER</p>
      </div>
      <div class="hy_about_hzhb_body">
        <div><img src="@/assets/imgs/par_11.jpg" alt=""></div> <!--滴滴-->
        <div><img src="@/assets/imgs/par_12.jpg" alt=""></div> <!--阿里巴巴-->
        <div><img src="@/assets/imgs/par_13.jpg" alt=""></div> <!--阿里云-->
        <div><img src="@/assets/imgs/par_14.jpg" alt=""></div> <!--天大-->
        <div><img src="@/assets/imgs/par_15.jpg" alt=""></div> <!--软银-->

        <div><img src="@/assets/imgs/par_21.jpg" alt=""></div> <!--株式会社-->
        <div><img src="@/assets/imgs/par_22.jpg" alt=""></div> <!--恒银金融-->
        <div><img src="@/assets/imgs/par_23.jpg" alt=""></div> <!--三甲-->
        <div><img src="@/assets/imgs/par_24.jpg" alt=""></div> <!--云译-->
        <div><img src="@/assets/imgs/par_25.jpg" alt=""></div> <!--中汽-->

        <div><img src="@/assets/imgs/par_31.jpg" alt=""></div> <!--塔米-->
        <div><img src="@/assets/imgs/par_32.jpg" alt=""></div> <!--INN-->
        <div><img src="@/assets/imgs/par_33.jpg" alt=""></div> <!--津云-->
        <div><img src="@/assets/imgs/par_34.jpg" alt=""></div> <!--烽火-->
        <div><img src="@/assets/imgs/par_35.jpg" alt=""></div> <!--天津广电-->
      </div>
    </div>
  </div>
</template>

<script>
import $ from "jquery"
import Banner from "@/components/Banner"
export default {
  name: "Introduction",
  components: {
    Banner,
  },
  data() {
    return {
      count: 1,
      goWight: ''
    }
  },
  mounted() {
    this.initMap()
    this.goWight = this.$refs.getheight.offsetHeight
  },
  methods: {
    top_page() {
      if(this.count == 0) {
        $(".hy_fzlc_body").animate({scrollTop: 0}, 800);
        this.count = 0;
      } else {
        this.count--;
        $(".hy_fzlc_body").animate({scrollTop: this.count * this.goWight}, 800);
      }
    },
    bottom_page() {
      if(this.count == 0) {
        $(".hy_fzlc_body").animate({scrollTop: this.goWight}, 1000);
      } else {
        $(".hy_fzlc_body").animate({scrollTop: this.count * this.goWight}, 1000);
      }
      let aaa = $(".hy_fzlc_body")[0].offsetHeight;
      let bbb = $(".timeline")[0].offsetHeight;
      if((aaa + this.count * this.goWight) > bbb) {
        return;
      }
      this.count++;
    },
    initMap(){
      //步骤：定义map变量 调用 qq.maps.Map() 构造函数   获取地图显示容器
      //设置地图中心点
      let myLatlng = new window.qq.maps.LatLng(39.167927,117.202304);
      //定义工厂模式函数
      let myOptions = {
        zoom: 18, //设置地图缩放级别
        center: myLatlng, //设置中心点样式
      }
      //获取dom元素添加地图信息
      window.map = new window.qq.maps.Map(document.getElementById("container"), myOptions);
      new window.qq.maps.Marker({
        position: myLatlng,
        map: window.map,
        type: 'bounce'
      });
    },
  }
}
</script>

<style lang="less" scoped>
.firstPlate{
  width: 1200px;
  margin: 0 auto;
  border-top: 1px solid transparent;
  border-bottom: 1px solid transparent;
  .hy_gsjj_body{
    display: flex;
    align-items: center;
    width: 100%;
    height: 495px;
    margin-bottom: 60px;
    >div {
      display: flex;
      flex-wrap: wrap;
      align-content: center;
      justify-content: center;
      height: 100%;
      color: #333;
      img{
        width: 580px;
        height: 495px;
      }
      p, div {
        display: flex;
        width: 500px;
      }
      p span{
        font-size: 18px;
      }
      p:nth-child(1){
        //margin-bottom: 30px;
      }
      p:nth-child(2){
        margin-bottom: 70px;
      }
      div{
        justify-content: flex-end;
        button{
          width: 120px;
          height: 44px;
          background: transparent;
          border-radius: 4px;
          border: 2px solid rgba(0,73,242,1);
          font-size: 18px;
          color: rgba(0,73,242,1);
          cursor: pointer;
        }
      }
    }
  }
}
.secondPlate {
  background: #F4F5FA;
  >div {
    position: relative;
    width: 1200px;
    margin: 0 auto;
    border-top: 1px solid transparent;
    border-bottom: 1px solid transparent;
  }
  .hy_fzlc_body{
    max-height: 680px;
    margin-bottom: 60px;
    overflow: hidden;
    .wrapper {
      box-sizing: border-box;
      width: 90%;
      margin: auto;
      max-width: 1200px;
    }
    .clearfix-time {
      display: block;
      zoom: 1;
    }
    .clearfix-time:after{
      content: '\20';
      display: block;
      clear: both;
      visibility: hidden;
      line-height: 0;
      height: 0;
    }
    .timeline {
      ul {
        align-content: center;
        background: url("~@/assets/imgs/about/sy51.png") repeat-y center;
        background-size: 0.3%;
        li b {
          position: absolute;
          top: 45px;
          left: auto;
          right: 0;
          display: block;
          width: 36px;
          height: 36px;
          margin: -18px;
          background: url("~@/assets/imgs/about/his_cs_n.png") no-repeat center center;
          background-size: 100% 100%;
        }
        li>i {
          position: absolute;
          top: 36%;
          left: -25px;
          display: flex;
          width: 50px;
          height: 68px;
          background: #F4F5FA;
        }
        li:nth-child(n)>i{
          left: auto;
          right: -25px;
        }
        li:nth-child(2n)>i{
          right: auto;
          left: -25px;
        }
        li>i>img{
          width: 100%;
        }
        li:nth-child(2n) b{
          left: 0;
          right: auto;
        }
        li div{
          position: relative;
          margin: 0 50px 18px;
        }
        li div i{
          position: absolute;
          top: 50%;
          right: -32px;
          width: 32px;
          height: 4px;
          margin-top: -2px;
          background: #aaa;
        }
        li:nth-child(2n) div i{
          left: -32px;
          right: auto;
        }
        li h4 {
          position: relative;
          display: block;
          width: 90px;
          height: 90px;
          line-height: 90px;
          text-align: center;
          background: url("~@/assets/imgs/about/his_cb_n.png") no-repeat center center;
          background-size: 100% 100%;
          border-radius: 100%;
          font-size: 28px;
          color: #0049F2;
          transition: 0.5s;
          text-decoration: none;
          text-transform: uppercase;
        }
        li h4:hover {
          background: url("~@/assets/imgs/about/his_cb_t.png") no-repeat center center;
        }
        li>span{
          display: inline-block;
          width: 100%;
          margin: 0 50px 37px;
          text-align: right;
          font-size: 18px;
          color: #0049F2;
        }
        li:nth-child(2n)>span{
          text-align: left;
        }
        li p{
          width: 100%;
          margin-right: 50px;
          margin-bottom: 30px;
          text-align: right;
        }
        li:nth-child(2n) p{
          margin-right: 0;
          margin-left: 50px;
          text-align: left;
        }
        li p span{
          display: block;
          font-size: 14px;
          color: #333;
        }
        li:nth-child(1){
          margin-top: 0;
        }
        li{
          position: relative;
          float: left;
          display: flex;
          flex-wrap: wrap;
          align-content: flex-start;
          justify-content: flex-end;
          width: 50%;
          height: max-content;
          min-height: 200px;
          margin-bottom: 20px;
        }
        li:nth-child(2n) {
          float: right;
          justify-content: left;
          margin-bottom: 65px;
        }
        li:nth-child(2) {
          margin-top: 100px;
        }
        li:last-child{
          margin-bottom: 0;
        }
      }
    }
  }
  .timelinePage{
    position: absolute;
    right: 0;
    top: 50%;
    display: flex;
    flex-wrap: wrap;
    align-content: space-between;
    width: 58px;
    margin-top: -158px;
    div{
      display: flex;
      align-items: center;
      justify-content: center;
      width: 58px;
      height: 58px;
      margin: 50px 0;
      cursor: pointer;
    }
    div.top_page{
      background: url("~@/assets/imgs/about/top_mouseleave.png") no-repeat center center;
      background-size: 100% 100%;
    }
    div.bottom_page{
      background: url("~@/assets/imgs/about/bottom_mouseleave.png") no-repeat center center;
      background-size: 100% 100%;
    }
    div.top_page:hover{
      background: url("~@/assets/imgs/about/top_mouseover.png") no-repeat center center;
      background-size: 100% 100%;
    }
    div.bottom_page:hover{
      background: url("~@/assets/imgs/about/bottom_mouseover.png") no-repeat center center;
      background-size: 100% 100%;
    }
  }
}
.thirdPlate {
  .hy_csrtd_body{
    display: flex;
    align-items: flex-start;
    justify-content: space-around;
    width: 1200px;
    margin: 0 auto 60px;
    >div{
      display: flex;
      flex-wrap: wrap;
      align-content: center;
      justify-content: center;
      width: 300px;
      height: 100%;
      color: #333;
      img{
        height: 150px;
        margin-bottom: 20px;
      }
      p, span{
        width: 100%;
        text-align: center;
      }
      p {
        font-size: 22px;
      }
      span.fubiaoti {
        font-size: 14px;
        margin-top: 5px;
        margin-bottom: 20px;
      }
      span {
        font-size: 16px;
      }
    }
  }
}
.fourthPlate {
  background: #F4F5FA;
  border-top: 1px solid transparent;
  border-bottom: 1px solid transparent;
  .hy_zzry_body{
    width: 1200px;
    margin: 0 auto 60px;
    .honor_txt{
      width: 100%;
      ul{
        display: flex;
        flex-wrap: wrap;
        align-items: flex-start;
        justify-content: space-between;
        width: 100%;
        li{
          position: relative;
          padding-left: 20px;
          margin-bottom: 30px;
          display: flex;
          width: 48%;
          i{
            position: absolute;
            top: 50%;
            left: 5px;
            width: 6px;
            height: 6px;
            margin-top: -3px;
            background: #000;
            border-radius: 50%;
          }
          span{
            font-size: 18px;
            color: #333;
          }
        }
        //li:nth-child(2n){
        //  width: 64%;
        //}
      }
    }
    .honor_img{
      display: flex;
      align-items: center;
      justify-content: space-between;
      img{
        width: 360px;
        height: 244px;
      }
    }
  }
}
.fivePlate {
  border-top: 1px solid transparent;
  border-bottom: 1px solid transparent;
  .hy_lxwm_body{
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    width: 1200px;
    margin: 0 auto;
    >div{
      position: relative;
      width: 50%;
    }
    .contact_info{
      display: flex;
      flex-wrap: wrap;
      align-content: space-between;
      height: 430px;
      .contact_info_top{
        display: flex;
        flex-wrap: wrap;
        p{
          display: flex;
          flex-wrap: wrap;
          width: 100%;
          >span{
            display: flex;
            width: 100%;
            margin-bottom: 10px;
            font-size: 18px;
            color: #333;
          }
        }
        p.company_name span:first-child{
          margin-bottom: 5px;
          font-size: 24px;
        }
        p.company_name span:last-child{
          margin-bottom: 37px;
          font-size: 14px;
        }
      }
      .contact_info_bottom{
        display: flex;
        flex-wrap: wrap;
        p{
          display: flex;
          width: 100%;
          span{
            display: block;
            margin-bottom: 10px;
            font-size: 18px;
            color: #333;
          }
        }
        img{
          width: 140px;
          height: 140px;
        }
      }
    }
    .containerMap {
      height: 495px;
      /deep/ .smnoprint {display: none}
      /deep/ div {
        div:nth-child(2) {
          a {
            display: none!important;
          }
        }
        div:nth-child(3) {
          span {
            display: none!important;
          }
        }
      }
    }
  }
}
.sixPlate {
  padding-bottom: 50px;
  .hy_about_hzhb_body{
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    width: 1200px;
    margin: 0 auto;
    div{
      display: flex;
      align-items: center;
      width: 210px;
      height: 65px;
      margin-bottom: 30px;
      border: 1px solid #eee;
      border-radius: 6px;
      overflow: hidden;
      img{
        width: 100%;
        height: 100%;
      }
    }
    div:nth-child(6n){
      margin-right: 0;
    }
  }
}
</style>